import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout.vue'
Vue.use(Router)


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const Routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/login.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/components/404.vue')
    },
    { path: '*', redirect: '/404' },
    {
        path: '/',
        name: 'Layout',
        component: Layout, // 导入组件 Layout
        redirect: "/index",
        children: [
            {
                path: '/index',
                name: 'home',
                component: () => import('@/views/home')
            },
            {
                path: '/organization-user',
                name: 'user',
                component: () => import('@/views/user')
            },
            {
                path: '/application',
                name: 'application',
                component: () => import('@/views/application'),
                children: [
                    {
                        path: '',
                        component: () => import('@/views/application/son')
                    },
                    {
                        path: 'one',
                        component: () => import('@/views/application/son/one.vue')
                    },
                ]
            },
            {
                path: '/dictionary',
                name: 'dictionary',
                component: () => import('@/views/dictionary')
            },
            {
                path: '/setting',
                name: 'setting',
                component: () => import('@/views/setting')
            },
            {
                path: '/user-feedback',
                name: 'user-feedback',
                component: () => import('@/views/user-feedback')
            },
        ]
    }
]

const createRouter = () => new Router({
    routes: Routes
})

const router = createRouter()

export default router;