export default {
    allValueNeed(code) {
        let allValue = JSON.parse(sessionStorage.getItem('allValue'))
        let info = {}
        allValue.forEach((item) => {
            if (code == item.code) {
                info = item.values
            }
        })
        return info
    }, 
}
