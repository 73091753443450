<template>
  <div class="header">
    <!-- logo -->
    <div class="logo">
      <span>巨能方程</span>
    </div>
    <!-- 顶部菜单栏 -->
    <el-menu
      ref="elMenu"
      :default-active="open"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#323c58"
      text-color="#858FAA"
      active-text-color="#fff"
      menu-trigger="click"
      :router="isthis"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
      >
        <el-submenu
          v-if="item.children.length>0"
          :index="item.name"
        >
          <template slot="title">
            <span>{{ item.name }}</span>
          </template>
          <div
            v-for="(sonitem, i) in item.children"
            :key="index + i"
          >
            <el-menu-item :index="'/' + sonitem.linkPageCode">
              <span>{{ sonitem.name }}</span>
            </el-menu-item>
          </div>
        </el-submenu>
        <el-menu-item
          v-else
          :index="'/' + item.linkPageCode"
        >
          <span>{{ item.name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
    <!-- 信息和退出 -->
    <div
      class="user"
      style="display:flex"
    >
      <!-- <span style="color:#fff;margin-right:15px">{{name}}</span> -->
      <span class="nz">
        <!-- <el-button type="text" @click="isShow = !isShow" style="color:#fff">{{
          name
        }}</el-button> -->
        <img
          style="background-color: #fff;border-radius: 50%;margin-right:10px;cursor: pointer;"
          width="40"
          height="40"
          src="@/assets/acter.png"
          alt=""
          @click="isShow = !isShow"
        >
        <div
          v-show="isShow"
          class="opertaing"
        >
          <el-button
            type="text"
            style="color:#fff"
            @click="
              dialogVisible1 = true;
              isShow = false;
            "
          >修改密码</el-button>
          <el-button
            v-if="role == 'SUPER-ADMIN'"
            type="text"
            style="margin-left:0px;color:#fff"
            @click="toSetPage"
          >设置</el-button>
          <el-button
            type="text"
            style="margin-left:0px;color:#fff"
            @click="
              dialogVisible = true;
              isShow = false;
            "
          >安全退出</el-button>
        </div>
      </span>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <span>你确定要退出吗？</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="logout()"
        >确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible1"
      width="400px"
    >
      <el-form
        ref="from"
        :inline="true"
        :model="from"
        class="demo-form-inline"
        :rules="rules"
      >
        <el-form-item
          label="旧密码"
          prop="oldPassword"
        >
          <el-input
            v-model="from.oldPassword"
            show-password
            placeholder="请输入旧密码"
          />
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="newPassword"
        >
          <el-input
            v-model="from.newPassword"
            show-password
            placeholder="请输入新密码"
          />
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="twoPassword"
        >
          <el-input
            v-model="from.twoPassword"
            show-password
            placeholder="请输入确认密码"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submitForm('from')"
        >确认修改</el-button>
        <el-button @click="resetForm('from')">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { logout } from "../api/myclueApi.js";
import { menus, loginUser, password } from "../api/public";
import { areas } from "../api/public";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      console.log(value);
      console.log(this.from.newPassword);
      if (value !== this.from.newPassword) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      activeIndex2: "1",
      open: "",
      list: "",
      isthis: true,
      dialogVisible: false,
      name: "",
      dialogVisible1: false,
      isShow: false,
      from: {
        oldPassword: "",
        newPassword: "",
        twoPassword: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
         { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 32, message: "密码长度是6-32位", trigger: "blur" },
        ],
        twoPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
      },
      role: "",
    };
  },
  mounted() {
    //设置页面刷新router指向
    var c = this.$route.path;
    this.open = `/${c.split("/")[1]}`;
    this.getAreas()
  },
  created() {
    //渲染菜单列表
    menus().then((res) => {
      console.log('菜单',res)
      this.list = res.data.list;
    });
    loginUser().then((res) => {
      console.log(res)
      this.name = res.data.name;
      console.log(res.data.role);
      this.role = res.data.role;
    });
  },
  methods: {
    getAreas() {
      areas().then((res) => {
        sessionStorage.setItem('areas',JSON.stringify(res.data.list) )
      });
    },
    //退出
    logout() {
      logout();
      window.sessionStorage.clear();
      this.$router.push({ path: "/login" });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          password(this.from).then((res) => {
            if (res.code === "11002") {
              this.$message.error("原密码错误");
            } else {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.dialogVisible1 = false;
            }
          });
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.dialogVisible1 = false;
      this.$refs[formName].resetFields();
    },
    toSetPage() {
      console.log("去往设置页面");
      this.$refs.elMenu.activeIndex = null
      this.$router.push("/setting");
      this.isShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  height: 60px;
  display: flex;
  background: #323c58;
  position: relative;
  .logo {
    width: 300px;
    padding-left: 30px;
    span {
      font-weight: bold;
      font-size: 30px;
      color: #fff;
      line-height: 55px;
    }
  }
  .user {
    color: #fff;
    position: absolute;
    right: 20px;
    top: 10px;
    .nz {
      position: relative;
      .opertaing {
        z-index: 100;
        text-align: center;
        position: absolute;
        right: -20px;
        top: 40px;
        padding: 10px 20px;
        background: #323c58;
        ::v-deep .el-button {
          padding: 5px 0px;
        }
      }
    }
  }
}
a {
  color: #fff;
}
::v-deep .el-menu-item > a {
  display: inline-block;
  padding: 0 20px;
  width: 100%;
  height: 100%;
}
.zk a {
  display: inline-block;
  padding: 0;
  width: 100%;
  height: 100%;
}
::v-deep .el-menu-item {
  padding: 0;
}
.el-menu--horizontal > div {
  float: left;
  height: 60px;
  line-height: 60px;
}
.el-menu-item {
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
  margin: 0 10px;
}
.el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-form--inline .el-form-item__label {
  width: 80px;
}
::v-deep .el-submenu__icon-arrow {
  position: absolute;
  top: 53%;
  right: 5px;
}
::v-deep .el-submenu__title {
  line-height: 60px;
  height: 60px;
}
::v-deep .el-dialog__body,
::v-deep .el-dialog__footer {
  text-align: center;
}

</style>
