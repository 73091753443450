<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  mounted(){
    console.log(1111);
  },
  mounted(){},
  methods: {
    
  },
};
</script>

<style lang="less">
&::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}

&::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

&:hover::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 53%, 0.4);
}

&:hover::-webkit-scrollbar-track {
  background: hsla(0, 0%, 53%, 0.1);
}
.el-popconfirm {
  .el-popconfirm__main {
    margin-bottom: 10px;
  }
}
</style>
