import axios from 'axios'
import public_request from './public_request'
//创建axios的一个实例 
// var baseURL = 'http://192.168.22.30:17100/api'
console.log(process.env.VUE_APP_URL)
var baseURL = process.env.VUE_APP_URL
var instance = axios.create({
    baseURL: baseURL,//接口统一域名
    timeout: 15000                     //设置超时
})
instance.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    if (sessionStorage.getItem("token")) {
        config.headers['authorization'] = sessionStorage.getItem("token")
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    console.log(error.response)
    // 对响应错误做点什么
    if (sessionStorage.getItem("token")) {
        console.log("response error :" + error);
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.log("token 过期");
                    refreshX();
                    return;
                case 417:
                    console.log("token 过期");
                    refreshX();
                    return;
            }
        }
    }
    return Promise.reject(error.response);
});
// refreshX()
function refreshX() {
    var refreshToken = sessionStorage.getItem("refreshToken");
    axios({
        method: 'put',
        url: baseURL + '/open/session',
        data: public_request({
            refreshToken: refreshToken
        }),
        headers: {
            "Authorization": sessionStorage.getItem("token")
        }
    }).then(
        (result) => {
            sessionStorage.setItem("token", result.data.data.token);
            sessionStorage.setItem("refreshToken", result.data.data.refreshToken);
            sessionStorage.setItem("code", result.data.code);
            location.reload();
        }
    )
        .catch((err) => {
            console.log('真的过期了')
            sessionStorage.setItem("code", err.response.data.code);
            location.reload();
        })
}

/**
 * 使用es6的export default导出了一个函数，导出的函数代替axios去帮我们请求数据，
 * 函数的参数及返回值如下：
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值

*/
export default instance
