import request from './request.js';
import public_request from './public_request'
export function menus() {
    return request({
        url: '/common/menus',
        method: 'get',
        params: public_request()
    })
}
export function type({dictionaryCode}) {
    return request({
        url: '/common/dictionary-code/values',
        method: 'get',
        params: public_request(
            {
                dictionaryCode
            }
        )
    })
}
export function loginUser() {
    return request({
        url: '/common/login-user',
        method: 'get',
        params: public_request()
    })
}
export function password({oldPassword,newPassword}) {
    return request({
        url: '/common/login-user/password',
        method: 'put',
        data: public_request(
            {
                oldPassword,
                newPassword
            }
        )
    })
}
// 获取区域划分
export function areas(){
    return request({
        url: '/common/areas',
        method: 'get',
        params: public_request({
            level:3
        })
    })
}
// 获取用户组
export function userGroups(){
    return request({
        url: '/common/user-groups',
        method: 'get',
        params: public_request()
    })
}
// 资源版本
export function resourceVersion(data){
    return request({
        url: '/common/resource-versions',
        method: 'get',
        params: public_request(data)
    })
}
export function all_value(data){
    return request({
        url: '/common/dictionaries-all',
        method: 'get',
        params: public_request(data)
    })
}



