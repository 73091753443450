import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import './assets/iconfont/iconfont.css'
import Vuex from 'vuex'
import store from './store'
import formValidation from "../src/assets/js/regular"  //导入js所在文件夹
// 这里是设置动态表格高度的
import tableHeight from './views/until/index'
// 公共方法
import common from './utils/common'
// 全局公共样式
import './assets/css/global.less'
Vue.prototype.common = common //使用公共方法
// 表格自适应指令
Vue.use(tableHeight)
Vue.prototype.formValidation = formValidation; //挂载到原型上
Vue.use(Vuex)
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  let code = sessionStorage.getItem("code")
  if (to.path == '/404') {
    next()
  } else {
    if (code === '0' && to.path == '/login') {
      next('')
    } else if (code === '0' && to.path != '/login') {
      next()
    } else if (code !== '0' && to.path == '/login') {
      next()
    } else if (code !== '0' && to.path != '/login') {
      next('./login')
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
