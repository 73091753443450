<template>
  <div>
    <div class="box">
      <!-- 顶部导航栏 -->
      <Header />
      <!-- 页面内容 -->
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>
<script>
import Header from "./header";
import { areas, all_value } from "@/api/public";
export default {
  components: {
    Header,
  },
  mounted() {
    console.log(1111);
    this.getAreas();
    this.getAllValue();
  },
  methods: {
    getAreas() {
      areas().then((res) => {
        sessionStorage.setItem("areas", JSON.stringify(res.data.list));
      });
    },
    getAllValue() {
      all_value().then((res) => {
        let data = res.data.list;
        sessionStorage.setItem("allValue", JSON.stringify(data));
      });
    },
  },
};
</script>
<style></style>
