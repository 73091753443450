import request from './request.js';
import public_request from './public_request'

export function login({ username, password }) {
    return request({
        url: '/open/session',
        method: 'post',
        data: public_request({
            username,
            password
        })
    })
}
export function refresh({ refreshToken }) {
    return request({
        url: '/open/session',
        method: 'put',
        data: public_request({
            refreshToken
        })
    })
}
export function logout() {
    return request({
        url: '/open/session',
        method: 'DELETE',
        data: public_request(

        )
    })
}

